import { useEffect, useState } from 'react'
import { shopifyClient } from '@/utils/shopifyClient'
import { parseCookies, setCookie } from 'nookies'
import { Cart } from '@/features/shopify/types'

export const useShopify = () => {
  const [cart, setCart] = useState<Cart | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const cart = await getCart()
      setCart(cart)
    })()
  }, [])

  async function getCart() {
    const cookies = parseCookies()
    const cartId = cookies.cartId
    if (cartId) {
      const fetchOperation = `
      query getCart($id: ID!) {
        cart(id: $id) {
          id
          checkoutUrl
          createdAt
          lines(first: 100) {
            nodes {
              id
              attributes {
               key
               value
              }
              merchandise {
                ... on ProductVariant {
                  product {
                    id
                    images(first: 10) {
                      nodes {
                        id
                        url
                      }
                    }
                    options(first: 100) {
                      id
                      name
                      optionValues {
                        id
                        name
                      }
                    }
                    title
                  }
                  selectedOptions {
                    name
                    value
                  }
                  price {
                    amount
                  }
                }
              }
              quantity
            }
          }
        }
      }
    `
      const { data, errors } = await shopifyClient.request(fetchOperation, {
        variables: {
          id: cartId
        }
      })
      if (errors) {
        console.error(errors)
        throw new Error(errors.message)
      }
      return data.cart
    }
  }

  async function createCart() {
    const operation = `
    mutation cartCreate {
      cartCreate {
        cart {
          id
          checkoutUrl
          lines(first: 100) {
            nodes {
              id
              attributes {
               key
               value
              }
              merchandise {
                ... on ProductVariant {
                  product {
                    id
                    images(first: 10) {
                      nodes {
                        id
                        url
                      }
                    }
                    options(first: 100) {
                      id
                      name
                      optionValues {
                        id
                        name
                      }
                    }
                    title
                  }
                  selectedOptions {
                    name
                    value
                  }
                  price {
                    amount
                  }
                }
              }
              quantity
            }
          }
        }
      }
    }
  `
    const { data, errors } = await shopifyClient.request(operation)
    if (errors) {
      throw new Error(errors.message)
    }
    setCookie(null, 'cartId', data.cartCreate.cart.id, {
      path: '/',
      maxAge: 60 * 60 * 24 // 1日
    })
    return data.cartCreate.cart
  }

  function resetCookie() {
    setCookie(null, 'cartId', '', {
      path: '/',
      maxAge: 0
    })
  }

  async function cartDeliveryAddressesAdd(
    cartId: string,
    address: {
      address1?: string
      address2?: string
      city?: string
      provinceCode?: string
      company?: string
      countryCode?: string
      firstName?: string
      lastName?: string
      zip?: string
    }
  ) {
    const operation = `
      mutation cartDeliveryAddressesAdd($addresses: [CartSelectableAddressInput!]!, $cartId: ID!) {
        cartDeliveryAddressesAdd(addresses: $addresses, cartId: $cartId) {
          cart {
            id
          }
          userErrors {
            field
            message
          }
        }
      }
    `
    const { errors } = await shopifyClient.request(operation, {
      variables: {
        cartId,
        addresses: [
          {
            address: {
              deliveryAddress: address
            }
          }
        ],
        oneTimeUse: true,
        selected: true,
        validationStrategy: 'COUNTRY_CODE_ONLY'
      }
    })
    if (errors) {
      console.error(errors)
      throw new Error(errors.message)
    }
  }

  async function cartBuyerIdentityUpdate(
    cartId: string,
    buyerIdentity: {
      email: string
    }
  ) {
    const operation = `
      mutation cartBuyerIdentityUpdate($cartId: ID!, $buyerIdentity: CartBuyerIdentityInput!) {
        cartBuyerIdentityUpdate(cartId: $cartId, buyerIdentity: $buyerIdentity) {
          cart {
            id
            buyerIdentity {
              email
            }
            checkoutUrl
          }
          userErrors {
            field
            message
          }
        }
      }
    `
    const { errors } = await shopifyClient.request(operation, {
      variables: {
        cartId,
        buyerIdentity: buyerIdentity
      }
    })
    if (errors) {
      console.error(errors)
      throw new Error(errors.message)
    }
  }

  async function cartAttributesUpdate(
    cartId: string,
    attributes: { key: string; value: string }[]
  ) {
    const operation = `
      mutation cartAttributesUpdate($attributes: [AttributeInput!]!, $cartId: ID!) {
        cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
          cart {
            id
          }
          userErrors {
            field
            message
          }
        }
      }
    `
    const { errors } = await shopifyClient.request(operation, {
      variables: {
        cartId,
        attributes: attributes
      }
    })
    if (errors) {
      console.error(errors)
      throw new Error(errors.message)
    }
  }

  /**
   *
   * @param cartId
   * @param lineId
   * @param quantity
   */
  async function cartLinesUpdate(cartId: string, lineId: string, quantity: number) {
    const operation = `
      mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          cart {
            id
            checkoutUrl
            createdAt
            lines(first: 100) {
              nodes {
                id
                attributes {
                 key
                 value
                }
                merchandise {
                  ... on ProductVariant {
                    product {
                      id
                      images(first: 10) {
                        nodes {
                          id
                          url
                        }
                      }
                      options(first: 100) {
                        id
                        name
                        optionValues {
                          id
                          name
                        }
                      }
                      title
                    }
                    selectedOptions {
                      name
                      value
                    }
                    price {
                      amount
                    }
                  }
                }
                quantity
              }
            }
          }
        }
      }
    `
    const { data, errors } = await shopifyClient.request(operation, {
      variables: {
        cartId,
        lines: [
          {
            id: lineId,
            quantity
          }
        ]
      }
    })
    if (errors) {
      console.error(errors)
      throw new Error(errors.message)
    }
    setCart(data.cartLinesUpdate.cart)
  }

  /**
   * @param cartId
   * @param lineId
   */
  async function cartLinesRemove(cartId: string, lineId: string) {
    const operation = `
      mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
          cart {
            id
            checkoutUrl
            createdAt
            lines(first: 100) {
              nodes {
                id
                attributes {
                 key
                 value
                }
                merchandise {
                  ... on ProductVariant {
                    product {
                      id
                      images(first: 10) {
                        nodes {
                          id
                          url
                        }
                      }
                      options(first: 100) {
                        id
                        name
                        optionValues {
                          id
                          name
                        }
                      }
                      title
                    }
                    selectedOptions {
                      name
                      value
                    }
                    price {
                      amount
                    }
                  }
                }
                quantity
              }
            }
          }
          userErrors {
            field
            message
          }
        }
      }
    `
    const { data, errors } = await shopifyClient.request(operation, {
      variables: {
        cartId: cartId,
        lineIds: [lineId]
      }
    })
    if (errors) {
      console.error(errors)
      throw new Error(errors.message)
    }
    setCart(data.cartLinesRemove.cart)
  }

  /**
   * カートに商品を追加する
   * @param cartId
   * @param merchandiseId
   * @param quantity
   */
  async function cartLinesAdd(cartId: string, merchandiseId: string, quantity: number) {
    const operation = `
      mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
        cartLinesAdd(cartId: $cartId, lines: $lines) {
          cart {
            id
            checkoutUrl
            createdAt
            lines(first: 100) {
              nodes {
                id
                attributes {
                 key
                 value
                }
                merchandise {
                  ... on ProductVariant {
                    product {
                      id
                      images(first: 10) {
                        nodes {
                          id
                          url
                        }
                      }
                      options(first: 100) {
                        id
                        name
                        optionValues {
                          id
                          name
                        }
                      }
                      title
                    }
                    selectedOptions {
                      name
                      value
                    }
                    price {
                      amount
                    }
                  }
                }
                quantity
              }
            }
          }
          userErrors {
            field
            message
          }
        }
      }
    `
    const { data, errors } = await shopifyClient.request(operation, {
      variables: {
        cartId: cartId,
        lines: [
          {
            merchandiseId: merchandiseId,
            quantity: quantity
          }
        ]
      }
    })
    if (errors) {
      console.error(errors)
      throw new Error(errors.message)
    }
    setCart(data.cartLinesAdd.cart)
  }

  function getTotalPrice() {
    if (!cart) return 0
    return cart.lines.nodes.reduce((acc, node) => {
      return acc + Number(node.merchandise.price.amount) * node.quantity
    }, 0)
  }

  return {
    cart,
    shopifyClient,
    getCart,
    createCart,
    resetCookie,
    getTotalPrice,
    cartLinesUpdate,
    cartLinesAdd,
    cartLinesRemove,
    cartBuyerIdentityUpdate,
    cartDeliveryAddressesAdd,
    cartAttributesUpdate
  }
}
